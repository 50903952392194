export default ($) => ({ 
	openPopup(id) {
    const idPop = $(id);
    idPop.addClass('show');
    $('body').addClass('lock');
  },
  
  closePopup(id) {
    const idPop = $(id);
		idPop.removeClass('show');
    $('body').removeClass('lock');
  },
	closeGeneral() {
		$('.overlay').on('click', function(e){
			$(this).parent().removeClass('show')
			$('body').removeClass('lock')
			$('.popup-container .popup-content').each(function() {
				let iframe = $(this).find('iframe');
				iframe.each(function(){
						$(this).attr('src', $(this).attr('src'));
						return false;
				});
			});
		})
		$('.close-pop').on('click', function(e){
			$(this).parents('.popup-container').removeClass('show')
			$('body').removeClass('lock')
			$('.popup-container .popup-content').each(function() {
				let iframe = $(this).find('iframe');
				iframe.each(function(){
						$(this).attr('src', $(this).attr('src'));
						return false;
				});
			});
		})
	},
	init(){
		this.closeGeneral()
	}
})

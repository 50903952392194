import $ from "jquery";
import {
  gsap,
  TweenLite,
  TweenMax,
  MotionPathPlugin,
  ScrollToPlugin,
  ScrollTrigger,
} from "gsap/all";

gsap.registerPlugin(
  TweenLite,
  TweenMax,
  MotionPathPlugin,
  ScrollToPlugin,
  ScrollTrigger
);

export default ($) => ({
  scrollHeader() {
    if ($(window).scrollTop() > 0) {
      $("header").addClass("float");
    } else {
      $("header").removeClass("float");
    }
  },
  searchDropdown() {
    $(".head-search > img").click(function () {
      if ($(this).parent().hasClass("active")) {
        $(this).parent().removeClass("active");
        TweenLite.to($(this).parent().find(".search-box"), 0.3, {
          css: {
            opacity: "0",
            "margin-top": "20px",
          },
          ease: "Power2.easeOut",
          onComplete: function () {
            $(".search-box").hide();
          },
        });
      } else {
        TweenLite.to($(this).parent().find(".search-box"), 0.3, {
          css: {
            display: "none",
            opacity: "0",
            "margin-top": "20px",
          },
          ease: "Power2.easeOut",
        });
        $(this).parent().addClass("active");
        TweenLite.to($(this).parent().find(".search-box"), 0.3, {
          css: {
            display: "block",
            opacity: "1",
            "margin-top": "0px",
          },
          ease: "Power2.easeOut",
        });

        setTimeout(function () {
          $(".search-box form input.input-focus").focus();
        }, 300);
      }
    });
    $(document).mouseup(function (e) {
      if ($(e.target).closest(".head-search").length === 0) {
        var drop = $(".search-box");
        $(".head-search").removeClass("active");
        TweenLite.to(drop, 0.3, {
          css: {
            opacity: "0",
            "margin-top": "20px",
          },
          ease: "Power2.easeOut",
          onComplete: function () {
            $(".search-box").hide();
          },
        });
      }
    });
  },
  menuMobile() {
    $(".menu-bar").click(function (e) {
      e.preventDefault();
      if (!$(this).hasClass("active")) {
        $(".overlay").fadeIn();
        $(this).addClass("active");
        $("nav").addClass("active");
        $("header").addClass("active");
        $("body").css("overflow", "hidden");
      } else {
        $("body").removeAttr("style");
        $(".overlay").fadeOut();
        $(this).removeClass("active");
        $("nav").removeClass("active");
        $("header").removeClass("active");
        if ($(window).scrollTop() > 0) {
          $("header").addClass("float");
        } else {
          $("header").removeClass("float");
        }
      }
    });
  },

  toggleDropdown() {
    const navLang = document.querySelector(".nav-lang");
    const navLink = navLang.querySelector(".nav-link");

    navLink.addEventListener("click", function () {
      const dropdown = navLang.querySelector(".dropdown");

      if (dropdown.classList.contains("show")) {
        dropdown.classList.remove("show");

        gsap.to(dropdown, 0.2, {
          css: {
            display: "none",
            opacity: "0",
            y: -10,
          },
          delay: 0,
          ease: "power2.out",
        });
      } else {
        dropdown.classList.add("show");
        gsap.set(dropdown, {
          display: "none",
          opacity: "0",
          y: -10,
        });

        gsap.to(dropdown, 0.2, {
          css: {
            display: "block",
            opacity: "1",
            y: 0,
          },
          delay: 0,
          ease: "power2.out",
        });
      }
    });

    window.onclick = function (event) {
      if (!event.target.matches(".nav-lang .nav-link")) {
        const dropdown = navLang.querySelector(".dropdown");

        dropdown.classList.remove("show");
        gsap.to(dropdown, 0.2, {
          css: {
            display: "none",
            opacity: "0",
            y: -10,
          },
          delay: 0,
          ease: "power2.out",
        });
      }
    };
  },
  init() {
    if (window.innerWidth > 1024) {
      this.scrollHeader();
      this.toggleDropdown();
    }
    this.searchDropdown();
    if (window.innerWidth < 1024) {
      this.menuMobile();
    }
  },
});

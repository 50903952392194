import * as $ from 'jquery'
import 'lazyload'
import helper  from './libs/helper'
import popup  from './libs/popup.js';
// import responsiveImage from './libs/responsive-images'

window.$ = $

$(document).ready(() => {
  let images = $("img")
	lazyload(images)
  
  // responsiveImage($).init($('.banner figure img'),{type: 'img'});
	helper($).init()
  popup($).closeGeneral()
})

$(window).scroll( () => {
	helper($).scrollHeader();
});

window.openPopup = (id) => {
	popup($).openPopup(id)
}
window.closePopup = (id) => {
	popup($).closePopup(id)
}
